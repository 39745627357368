import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function PlausibleAnalytics() {
  const location = useLocation();

  useEffect(() => {
    const script = document.createElement('script');
    script.defer = true;
    script.setAttribute('data-domain', 'alpha.lhumos.org');
    script.src = 'https://plausible.io/js/script.js';
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  useEffect(() => {
    window.plausible = window.plausible || function() { 
      (window.plausible.q = window.plausible.q || []).push(arguments);
    };
    window.plausible('pageview');
  }, [location]);

  return null;
}