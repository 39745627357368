import React from 'react';

const Backdrop = ({ onClick }) => (
  <div 
    className="fixed inset-0 z-40 bg-black/50 backdrop-blur-sm"
    onClick={onClick}
    aria-hidden="true"
    style={{ animation: 'fadeIn 300ms ease-in-out' }}
  >
    <style>{`
      @keyframes fadeIn {
        from { opacity: 0; }
        to { opacity: 1; }
      }
    `}</style>
  </div>
);

export default React.memo(Backdrop);