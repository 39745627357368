import React, { useRef, useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import Slider from "react-slick";
import VideoPlayer from "react-video-js-player";
import Breadcrumbs from "./components/Breadcrumbs";
import SharedFiles from "./components/SharedFiles";
import PdfContainer from "./components/PdfContainer";
import UrlContainer from "./components/UrlContainer";
import ZipContainer from "./components/ZipContainer";
import LoadingScreen from "./components/LoadingScreen";
import LhumosSpaces from "./spaces.json";
import {
  Play, Pause, SkipForward, SkipBack, Volume2, VolumeX,
  Maximize, Minimize, AlertCircle, ChevronDown, ChevronUp
} from "lucide-react";
import {
  ApiGet,
  getFilesByDatasetId,
  getDatasetMetadataByFileId,
  getDatasetsByCollectionId,
  getCollectionById,
} from "./components/APImodules.js";
import ExtractJsonFromDescription from "./components/ExtractJsonFromDescription.js";

function Player() {
  const [isLoading, setIsLoading] = useState(true);
  const [slides, setSlides] = useState([]);
  const [playlist, setPlaylist] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [descriptionVisible, setDescriptionVisible] = useState(!isMobile);
  const [slideIndex, setSlideIndex] = useState(0);
  const [files, setFiles] = useState([]);
  const [dataset, setDataset] = useState([]);
  const [error, setError] = useState(null);
  const [videoState, setVideoState] = useState({
    isPlaying: false,
    currentTime: 0,
    duration: 0,
    volume: localStorage.getItem('playerVolume') ? 
      parseFloat(localStorage.getItem('playerVolume')) : 0.5,
    isMuted: localStorage.getItem('playerMuted') === 'true',
    isFullscreen: false,
    playbackRate: 1,
  });

  const {
    spaceId: SPACE_INDEX,
    videoNumber: VIDEO_NUMBER,
    collectionId: COLLECTION_ID,
    datasetId: DATASET_ID,
  } = useParams();

  const playerRef = useRef(null);
  const sliderRef = useRef(null);
  const videoContainerRef = useRef(null);

  useEffect(() => {
    setIsLoading(true);
    GetAllData();
    window.scrollTo(0, 0);

    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [SPACE_INDEX, COLLECTION_ID, DATASET_ID, VIDEO_NUMBER]);

  async function GetAllData() {
    try {
      const filesData = await getFilesByDatasetId(DATASET_ID);
      if (!filesData || filesData.length === 0) {
        throw new Error(`No files found for dataset ${DATASET_ID}`);
      }
      
      setFiles(filesData);
      
      const videoFile = filesData.find(file => 
        file.contentType === "video/mp4" || 
        file.contentType === "video/x-matroska"
      );
      
      if (!videoFile) {
        throw new Error(`No video file found in dataset ${DATASET_ID}`);
      }

      const datasetData = await getDatasetMetadataByFileId(videoFile.id);
      if (!datasetData || datasetData.length === 0) {
        throw new Error(`No metadata found for file ${videoFile.id}`);
      }
      
      setDataset(datasetData);
      if (datasetData[0]?.content?.listslides) {
        setSlides(datasetData[0].content.listslides);
      }

      const playlistResult = await getDatasetsByCollectionId(COLLECTION_ID, "test");
      const filteredPlaylistResult = playlistResult.filter(
        dataset => !dataset.name.includes("[files]")
      );

      const collectionData = await getCollectionById(COLLECTION_ID);
      if (collectionData) {
        const { json: playlistOrder } = ExtractJsonFromDescription(collectionData.description);
        if (playlistOrder && Array.isArray(playlistOrder)) {
          const orderedAndFilteredPlaylist = playlistOrder.filter(dataset =>
            filteredPlaylistResult.some(
              filteredDataset => filteredDataset.id === dataset.id
            )
          );
          setPlaylist(orderedAndFilteredPlaylist);
        } else {
          setPlaylist(filteredPlaylistResult);
        }
      } else {
        setPlaylist(filteredPlaylistResult);
      }

    } catch (error) {
      console.error('Error in GetAllData:', error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (!isLoading && playlist.length > 0) {
      const jsonString = playlist[VIDEO_NUMBER].description;
      const extractedJSON = extractJSONFromString(jsonString);
      if (extractedJSON !== null) {
        const filteredSlides = extractedJSON.filter(
          (slide) => slide.isRemoved === false
        );
        setSlides(filteredSlides);
      }
    }
  }, [isLoading, playlist, VIDEO_NUMBER]);

  useEffect(() => {
    if (slides.length > 0) {
      const intervalId = setInterval(() => {
        if (playerRef.current) {
          const currentTime = playerRef.current.player.currentTime();
          const closestSlideIndex = calculateClosestPassedSlideIndex(currentTime);
          setSlideIndex(closestSlideIndex);
        }
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [slides]);

  function extractJSONFromString(string) {
    const regex = /\[slides\](.*?)\[endslides\]/s;
    const match = string.match(regex);
    if (match && match[1]) {
      const json = match[1].trim();
      return JSON.parse(json);
    }
    return null;
  }

  function calculateClosestPassedSlideIndex(currentTime) {
    let closestIndex = -1;
    let minDiff = Number.MAX_SAFE_INTEGER;

    slides.forEach((slide, index) => {
      if (slide[3] !== undefined && currentTime >= slide[3]) {
        const diff = currentTime - slide[3];
        if (diff < minDiff) {
          minDiff = diff;
          closestIndex = index;
        }
      }
    });

    return closestIndex;
  }

  const toggleFullscreen = () => {
    if (!videoContainerRef.current) return;

    const element = videoContainerRef.current;
    
    if (!document.fullscreenElement) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      }
      setVideoState(prev => ({ ...prev, isFullscreen: true }));
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      }
      setVideoState(prev => ({ ...prev, isFullscreen: false }));
    }
  };

  const handleKeyPress = (e) => {
    if (document.activeElement?.tagName === 'INPUT') return;

    switch (e.key.toLowerCase()) {
      case ' ':
      case 'k':
        e.preventDefault();
        togglePlay();
        break;
      case 'f':
        e.preventDefault();
        toggleFullscreen();
        break;
      case 'm':
        e.preventDefault();
        toggleMute();
        break;
      case 'arrowleft':
        e.preventDefault();
        if (playerRef.current) {
          const currentTime = playerRef.current.player.currentTime();
          seek(currentTime - 5);
        }
        break;
      case 'arrowright':
        e.preventDefault();
        if (playerRef.current) {
          const currentTime = playerRef.current.player.currentTime();
          seek(currentTime + 5);
        }
        break;
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, []);

  const togglePlay = () => {
    if (!playerRef.current) return;
    
    if (videoState.isPlaying) {
      playerRef.current.player.pause();
    } else {
      playerRef.current.player.play();
    }
    setVideoState(prev => ({ ...prev, isPlaying: !prev.isPlaying }));
  };

  const seek = (time) => {
    if (!playerRef.current) return;
    playerRef.current.player.currentTime(time);
  };

  const toggleMute = () => {
    if (!playerRef.current) return;
    
    const newMutedState = !videoState.isMuted;
    playerRef.current.player.muted(newMutedState);
    localStorage.setItem('playerMuted', String(newMutedState));
    setVideoState(prev => ({ ...prev, isMuted: newMutedState }));
  };

  const handleVolumeChange = (value) => {
    if (!playerRef.current) return;
    
    const volume = parseFloat(value);
    playerRef.current.player.volume(volume);
    localStorage.setItem('playerVolume', String(volume));
    setVideoState(prev => ({ ...prev, volume }));
  };

  const handlePlaybackRateChange = (rate) => {
    if (!playerRef.current) return;
    
    const newRate = parseFloat(rate);
    playerRef.current.player.playbackRate(newRate);
    setVideoState(prev => ({ ...prev, playbackRate: newRate }));
  };

  const sliderSettings = {
    infinite: false,
    lazyLoad: false,
    speed: 300,
    slidesToShow: isMobile ? 2 : 4,
    dots: false,
    centerMode: false,
    beforeChange: (current, next) => setSlideIndex(next),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2
        }
      }
    ]
  };

  const playlistSettings = {
    infinite: false,
    lazyLoad: true,
    speed: 300,
    vertical: true,
    focusOnSelect: true,
    slidesToShow: playlist.length > 4 ? 4 : playlist.length - 1,
    centerMode: false,
    centerPadding: 100,
  };

  if (isLoading) return <LoadingScreen />;

  const videoSource = dataset.length > 0
    ? `https://clowder.eessi.science/api/previews/${dataset[0].content.previews.mp4}#.mp4`
    : "";

  return (
    <div className="min-h-screen bg-gradient-to-b"
      style={{
        backgroundImage: `linear-gradient(to bottom, ${LhumosSpaces[SPACE_INDEX].color1}, #000000)`
      }}>
      <Breadcrumbs videoName={playlist[VIDEO_NUMBER]?.name} />
      
      <div className="max-w-screen-2xl mx-auto px-4 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
          {/* Main Video Section */}
          <div className="lg:col-span-9">
            <div className="rounded-3xl overflow-hidden bg-gradient-to-b from-black/40 to-transparent backdrop-blur-sm p-4">
              {error && (
                <div className="mb-4 p-4 bg-red-500/80 text-white rounded-lg flex items-center">
                  <AlertCircle className="mr-2" />
                  <span>{error}</span>
                </div>
              )}

              <div ref={videoContainerRef} 
                className="relative rounded-2xl overflow-hidden bg-black w-full"
                style={{ paddingTop: '56.25%' }}> {/* 16:9 Aspect Ratio */}
                <div className="absolute inset-0">
                  <VideoPlayer
                    options={{
                      autoplay: false,
                      controls: true,
                      responsive: true,
                      fluid: true,
                      playbackRates: [0.5, 1, 1.25, 1.5, 2],
                      poster: `https://clowder.eessi.science/fileThumbnail/${playlist[VIDEO_NUMBER]?.thumbnail}/blob`
                    }}
                    src={videoSource}
                    className="w-full h-full"
                    ref={playerRef}
                    onPlay={() => setVideoState(prev => ({ ...prev, isPlaying: true }))}
                    onPause={() => setVideoState(prev => ({ ...prev, isPlaying: false }))}
                  />

                  {/* Custom Controls Overlay */}
                  <div 
                    className="absolute inset-0 flex flex-col justify-end bg-gradient-to-t from-black/60 via-black/30 to-transparent opacity-0 hover:opacity-100 transition-opacity duration-300"
                    style={{ pointerEvents: 'none' }}
                  >
                    <div className="p-4" style={{ pointerEvents: 'auto' }}>
                      <div className="flex items-center justify-between text-white">
                        <div className="flex items-center space-x-4">
                          <button 
                            onClick={togglePlay}
                            className="p-2 hover:bg-white/20 rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-white/50"
                          >
                            {videoState.isPlaying ? <Pause size={24} /> : <Play size={24} />}
                          </button>

                          <div className="flex items-center space-x-2">
                            <button 
                              onClick={toggleMute}
                              className="p-2 hover:bg-white/20 rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-white/50"
                            >
                              {videoState.isMuted ? <VolumeX size={24} /> : <Volume2 size={24} />}
                            </button>
                            <input
                              type="range"
                              min="0"
                              max="1"
                              step="0.1"
                              value={videoState.volume}
                              onChange={(e) => handleVolumeChange(e.target.value)}
                              className="w-24 accent-white"
                            />
                          </div>
                        </div>

                        <div className="flex items-center space-x-4">
                          <select
                            value={videoState.playbackRate}
                            onChange={(e) => handlePlaybackRateChange(e.target.value)}
                            className="bg-black/40 text-white border border-white/20 rounded px-2 py-1 focus:outline-none focus:ring-2 focus:ring-white/50"
                          >
                            <option value="0.5">0.5x</option>
                            <option value="1">1x</option>
                            <option value="1.25">1.25x</option>
                            <option value="1.5">1.5x</option>
                            <option value="2">2x</option>
                          </select>

                          <button 
                            onClick={toggleFullscreen}
                            className="p-2 hover:bg-white/20 rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-white/50"
                          >
                            {videoState.isFullscreen ? <Minimize size={24} /> : <Maximize size={24} />}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Slides Section */}
              <div className="mt-8">
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-white text-xl font-semibold">Slides</h3>
                  <button
                    onClick={() => sliderRef.current?.slickGoTo(slideIndex)}
                    className="px-4 py-2 bg-white/10 hover:bg-white/20 text-white rounded-lg transition-colors"
                    style={{ backgroundColor: LhumosSpaces[SPACE_INDEX].color1 }}
                  >
                    Skip to Current Slide
                  </button>
                </div>

                <Slider {...sliderSettings} ref={sliderRef}>
                  {slides.map((slide, idx) => (
                    <div key={idx} className="px-2">
                      <button
                        onClick={() => seek(slide[3])}
                        className={`relative w-full rounded-xl overflow-hidden transition-transform hover:scale-95 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                          idx === slideIndex ? 'ring-2' : ''
                        }`}
                        style={{ 
                          borderColor: idx === slideIndex ? LhumosSpaces[SPACE_INDEX].accent : 'transparent'
                        }}
                      >
                        <div 
                          className="absolute top-2 left-2 w-8 h-8 rounded-full flex items-center justify-center text-white text-sm font-semibold z-10"
                          style={{ backgroundColor: LhumosSpaces[SPACE_INDEX].accent }}
                        >
                          {idx + 1}
                        </div>
                        <img
                          src={`https://clowder.eessi.science/api/previews/${slide[2]}`}
                          alt={`Slide ${idx + 1}`}
                          className="w-full aspect-video object-cover rounded-xl"
                          loading="lazy"
                        />
                      </button>
                    </div>
                  ))}
                </Slider>
              </div>

              {/* Description Section */}
              {playlist[VIDEO_NUMBER]?.description && (
                (() => {
                  const cleanDescription = playlist[VIDEO_NUMBER].description
                    .replace(/\[slides\][\s\S]*?\[endslides\]/g, "")
                    .trim();
                  
                  if (!cleanDescription) return null;

                  const needsExpansion = cleanDescription.length > 300;

                  return (
                    <div className="mt-8">
                      <div className={`relative rounded-xl overflow-hidden transition-all duration-300 ${
                        needsExpansion ? 'bg-white/10' : 'bg-white/5'
                      } backdrop-blur-sm`}>
                        <div className="p-6">
                          <h3 className="text-white text-xl font-semibold mb-4">Description</h3>
                          <div 
                            className={`text-white/80 overflow-hidden transition-all duration-300 space-y-4 ${
                              needsExpansion && !descriptionVisible ? 'max-h-24 cursor-pointer' : 'max-h-[800px]'
                            }`}
                            onClick={() => needsExpansion && !descriptionVisible && setDescriptionVisible(true)}
                          >
                            {cleanDescription.split('\n\n')
                              .map((paragraph, index) => (
                                <p key={index}>{paragraph}</p>
                              ))
                            }
                          </div>

                          {needsExpansion && (
                            <>
                              {!descriptionVisible && (
                                <div 
                                  className="absolute bottom-0 left-0 right-0 h-12 bg-gradient-to-t from-black/20 to-transparent cursor-pointer"
                                  onClick={() => setDescriptionVisible(true)}
                                />
                              )}
                              <button
                                onClick={() => setDescriptionVisible(!descriptionVisible)}
                                className="absolute bottom-2 right-2 text-white/80 hover:text-white transition-colors p-2 hover:bg-white/10 rounded-lg"
                              >
                                {descriptionVisible ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })()
              )}
            </div>
          </div>

          {/* Sidebar */}
          <div className="lg:col-span-3 space-y-6">
            {/* Shared Files */}
            <SharedFiles collectionId={COLLECTION_ID} spaceId={SPACE_INDEX} />

            {/* Attached Files */}
            {files.length > 1 && (
              <div className="rounded-3xl overflow-hidden bg-gradient-to-b from-black/40 to-transparent backdrop-blur-sm p-4">
                <h3 className="text-white text-xl font-semibold mb-4">Attached Files</h3>
                <div className="space-y-3">
                  {files.map((file, idx) => (
                    <div key={idx} className="relative">
                      {file.contentType === "application/pdf" && (
                        <PdfContainer spaceIndex={SPACE_INDEX}>{file}</PdfContainer>
                      )}
                      {file.contentType === "text/url" && (
                        <UrlContainer>{file}</UrlContainer>
                      )}
                      {file.contentType === "multi/files-zipped" && (
                        <ZipContainer>{file}</ZipContainer>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* Playlist */}
            <div className="rounded-3xl overflow-hidden bg-gradient-to-b from-black/40 to-transparent backdrop-blur-sm p-4">
              <h3 className="text-white text-xl font-semibold mb-4">Playlist</h3>
              <Slider {...playlistSettings}>
                {playlist.map((video, idx) => (
                  <Link
                    key={video.id}
                    to={`/Player/${SPACE_INDEX}/${idx}/${COLLECTION_ID}/${video.id}`}
                    className="block p-2"
                  >
                    <div 
                      className={`relative rounded-xl overflow-hidden transition-transform hover:scale-95 ${
                        idx === Number(VIDEO_NUMBER) ? 'ring-2' : ''
                      }`}
                      style={{ 
                        borderColor: idx === Number(VIDEO_NUMBER) ? LhumosSpaces[SPACE_INDEX].accent : 'transparent'
                      }}
                    >
                      <div
                        className="relative aspect-video bg-cover bg-center"
                        style={{
                          backgroundImage: `url("https://clowder.eessi.science/fileThumbnail/${video.thumbnail}/blob")`
                        }}
                      >
                        <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
                        <div className="absolute bottom-2 right-2 w-8 h-8 rounded-full flex items-center justify-center text-white text-sm font-semibold"
                          style={{ backgroundColor: LhumosSpaces[SPACE_INDEX].accent }}>
                          {idx + 1}
                        </div>
                        <div className="absolute bottom-2 left-2 right-12 p-2">
                          <h4 className="text-white text-sm font-medium truncate">
                            {video.name}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
      {/* Remove duplicate PDF container */}
    </div>
  );
}

export default Player;