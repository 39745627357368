import React, { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Database, Link as LinkIcon, ChevronDown, LayoutGrid, LayoutList, Video } from "lucide-react";
import Footer from "./components/Footer";
import CollectionContainer from "./components/CollectionContainer";
import CompactCollectionContainer from "./components/CompactCollectionContainer";
import Breadcrumbs from "./components/Breadcrumbs";
import LhumosSpaces from "./spaces.json";
import LoadingScreen from "./components/LoadingScreen";
import { getDatasetsBySpaceId, getCollectionsBySpaceId, getSpaceById } from "./components/APImodules.js";
import lhumosCache from "./components/lhumosCache";

function SingleSpace() {
  const [isLoading, setIsLoading] = useState(true);
  const [space, setSpace] = useState(null);
  const [collections, setCollections] = useState([]);
  const [datasets, setDatasets] = useState([]);
  const [expandedCollectionId, setExpandedCollectionId] = useState(null);
  const [spacesStats, setSpacesStats] = useState({ collections: 0, datasets: 0 });
  const [viewMode, setViewMode] = useState(() => localStorage.getItem('lhumosViewMode') || 'default');

  const { spaceId } = useParams();
  const SPACE_INDEX = spaceId;
  const SPACE_ID = LhumosSpaces[SPACE_INDEX]?.id;
  const spaceDetails = useMemo(() => LhumosSpaces[SPACE_INDEX], [SPACE_INDEX]);

  useEffect(() => {
    localStorage.setItem('lhumosViewMode', viewMode);
  }, [viewMode]);

  const reorderCollections = (collections, order) => {
    const orderedCollections = order.map(id => collections.find(collection => collection.id === id)).filter(Boolean);
    const remainingCollections = collections.filter(collection => !order.includes(collection.id));
    return [...orderedCollections, ...remainingCollections];
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [fetchedSpace, fetchedCollections, fetchedDatasets] = await Promise.all([
          getSpaceById(SPACE_ID),
          getCollectionsBySpaceId(SPACE_ID),
          getDatasetsBySpaceId(SPACE_ID)
        ]);

        setSpace(fetchedSpace);
        const collectionOrder = spaceDetails?.collectionOrder || [];
        const orderedCollections = reorderCollections(fetchedCollections, collectionOrder);
        setCollections(orderedCollections);
        setDatasets(fetchedDatasets);

        // Updated stats calculation
        try {
          const { rawStructure } = await lhumosCache.getCachedData();
          const spaceData = rawStructure.find(s => s.id === SPACE_ID);
          
          if (spaceData) {
            const countDatasets = (children) => {
              return children.reduce((count, child) => {
                if (child.group === 'dataset') return count + 1;
                if (child.children) return count + countDatasets(child.children);
                return count;
              }, 0);
            };

            setSpacesStats({
              collections: spaceData.children?.filter(c => c.group === 'collection').length || 0,
              datasets: countDatasets(spaceData.children || [])
            });
          }
        } catch (error) {
          console.error("Error calculating stats:", error);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setIsLoading(false);
    };

    if (SPACE_ID) {
      fetchData();
      window.scrollTo(0, 0);
    }
  }, [SPACE_ID, spaceDetails]);

  if (isLoading || !spaceDetails) return <LoadingScreen />;

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow relative" 
        style={{ backgroundImage: `linear-gradient(to bottom, ${spaceDetails.color1}, #000000)` }}>
        <Breadcrumbs />
        
        {/* Hero Section */}
        <div className="relative bg-cover bg-fixed bg-center h-96 overflow-hidden"
             style={{ backgroundImage: `url(${spaceDetails.bgurl})` }}>
          <div className="absolute inset-0 bg-gradient-to-b from-black/50 via-transparent to-black" />
          
          <div className="relative h-full max-w-screen-xl mx-auto px-4 md:px-8 flex items-center">
            <div className="bg-black/30 backdrop-blur-md rounded-3xl p-4 md:p-8 w-full">
              <div className="flex flex-col md:flex-row items-center md:items-start gap-8">
                <div className="relative group">
                  <div className="absolute inset-0 rounded-xl blur-xl transition-all duration-300 group-hover:scale-110 opacity-50"
                       style={{ backgroundColor: spaceDetails.accent }} />
                  <img src={spaceDetails.iconurl}
                       alt={space?.name}
                       className="w-32 h-32 rounded-xl relative border-2 transition-transform duration-300 group-hover:scale-105"
                       style={{ borderColor: spaceDetails.accent }} />
                </div>

                <div className="flex-1">
                  <div className="flex flex-col md:flex-row justify-between items-center md:items-start gap-4">
                    <h1 className="text-4xl md:text-5xl font-bold text-white text-center md:text-left">
                      {space?.name}
                    </h1>
                    {spaceDetails.website && (
                      <a href={spaceDetails.website}
                         target="_blank"
                         rel="noopener noreferrer"
                         className="group flex items-center gap-2 px-6 py-3 bg-white/10 hover:bg-white/20 rounded-full backdrop-blur-sm transition-all duration-300 hover:scale-105">
                        <LinkIcon size={18} className="text-white transition-transform duration-300 group-hover:-rotate-12" />
                        <span className="text-white">Visit Website</span>
                      </a>
                    )}
                  </div>

                  <p className="text-white/90 text-lg my-6 leading-relaxed max-w-3xl text-center md:text-left">
                    {space?.description}
                  </p>

                  <div className="flex flex-wrap justify-center md:justify-start gap-4">
                    <div className="flex items-center gap-2 bg-white/10 px-4 py-2 rounded-full backdrop-blur-sm">
                      <Database size={18} className="text-white" />
                      <span className="text-white text-sm">{spacesStats.collections} Collections</span>
                    </div>
                    <div className="flex items-center gap-2 bg-white/10 px-4 py-2 rounded-full backdrop-blur-sm">
                      <Video size={18} className="text-white" />
                      <span className="text-white text-sm">{spacesStats.datasets} Videos</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Collections Section */}
        <div className="max-w-screen-xl mx-auto px-4 py-12">
          <div className="flex flex-col md:flex-row justify-between items-center gap-4 mb-8">
            <h2 className="text-3xl font-bold text-white">Collections</h2>
            <div className="flex items-center gap-3 bg-white/5 p-1 rounded-lg backdrop-blur-sm">
              <button onClick={() => setViewMode('default')}
                      className={`p-3 rounded-lg transition-all duration-300 ${
                        viewMode === 'default' ? 'bg-white/20 text-white scale-105' : 'text-white/60 hover:text-white hover:bg-white/10'
                      }`}>
                <LayoutList size={24} />
              </button>
              <button onClick={() => setViewMode('compact')}
                      className={`p-3 rounded-lg transition-all duration-300 ${
                        viewMode === 'compact' ? 'bg-white/20 text-white scale-105' : 'text-white/60 hover:text-white hover:bg-white/10'
                      }`}>
                <LayoutGrid size={24} />
              </button>
            </div>
          </div>

          <div className={`
            ${viewMode === 'default' ? 'space-y-4' : 'grid grid-cols-1 md:grid-cols-2 gap-6'}
            pb-16
          `}>
            {collections.map((collection, idx) => (
              viewMode === 'default' ? (
                <CollectionContainer
                  key={collection.id}
                  name={collection.name}
                  spaceIndex={SPACE_INDEX}
                  index={idx}
                  isExpanded={expandedCollectionId === collection.id}
                  onToggleExpand={() => setExpandedCollectionId(
                    expandedCollectionId === collection.id ? null : collection.id
                  )}
                  isTopLevel={true}
                  spaceDetails={spaceDetails}
                >
                  {collection}
                </CollectionContainer>
              ) : (
                <CompactCollectionContainer
                  key={collection.id}
                  spaceIndex={SPACE_INDEX}
                  collections={collections}
                  datasets={datasets}
                >
                  {collection}
                </CompactCollectionContainer>
              )
            ))}
          </div>
        </div>
      </div>
      
      <Footer className="mt-auto" />
    </div>
  );
}

export default SingleSpace;