import React, { useState, useEffect } from "react";
import { Database, RefreshCw, Search, Shell, Activity, Clock } from "lucide-react";
import LoadingScreen from "./components/LoadingScreen";
import ApiGet from "./components/APImodules";
import lhumosCache from "./components/lhumosCache";

const API_HEALTH_CHECKS = [
  { name: "Spaces API", endpoint: "/spaces" },
  { name: "Collections API", endpoint: "/spaces/5a79b568e4b05cb28bae3f60/collections" },
  { name: "Datasets API", endpoint: "/spaces/5a79b568e4b05cb28bae3f60/datasets" },
  { name: "Files API", endpoint: "/datasets/65646ad5e4b0c3a3247c3456/files" },
  { name: "Search API", endpoint: "/search?query=test" },
  { name: "Metadata API", endpoint: "/files/65646ad5e4b0c3a3247c3456/metadata" }
];

function CacheStats({ stats, onRefresh }) {
  const [timeRemaining, setTimeRemaining] = useState("");
  
  useEffect(() => {
    const updateTimeRemaining = () => {
      const timestamp = localStorage.getItem('lhumosStructureTimestamp');
      if (timestamp) {
        const expiryTime = parseInt(timestamp) + (24 * 60 * 60 * 1000);
        const now = Date.now();
        const remaining = expiryTime - now;
        
        if (remaining > 0) {
          const hours = Math.floor(remaining / (60 * 60 * 1000));
          const minutes = Math.floor((remaining % (60 * 60 * 1000)) / (60 * 1000));
          setTimeRemaining(`${hours}h ${minutes}m`);
        } else {
          setTimeRemaining("Expired");
        }
      }
    };

    updateTimeRemaining();
    const interval = setInterval(updateTimeRemaining, 60000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="bg-white/10 backdrop-blur-md rounded-xl p-6">
      <div className="flex justify-between items-center mb-4">
        <div>
          <h2 className="text-2xl font-bold text-white flex items-center gap-2">
            <Database className="h-6 w-6" />
            Cache Statistics
          </h2>
          <div className="flex items-center gap-2 text-white/70 text-sm mt-1">
            <Clock className="h-4 w-4" />
            Next refresh in: {timeRemaining}
          </div>
        </div>
        <button
          onClick={onRefresh}
          className="flex items-center gap-2 px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-lg transition-colors"
        >
          <RefreshCw className="h-4 w-4" />
          Force Refresh Cache
        </button>
      </div>
      
      {stats && (
        <div className="grid grid-cols-3 gap-4 text-white">
          <div className="bg-white/5 rounded-lg p-4">
            <h3 className="text-lg font-semibold">Spaces</h3>
            <p className="text-3xl font-bold">{stats.spaceCount}</p>
          </div>
          <div className="bg-white/5 rounded-lg p-4">
            <h3 className="text-lg font-semibold">Collections</h3>
            <p className="text-3xl font-bold">{stats.collectionCount}</p>
          </div>
          <div className="bg-white/5 rounded-lg p-4">
            <h3 className="text-lg font-semibold">Datasets</h3>
            <p className="text-3xl font-bold">{stats.datasetCount}</p>
          </div>
        </div>
      )}
    </div>
  );
}

function DevExplorer() {
  const [isLoading, setIsLoading] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);
  const [spaceId, setSpaceId] = useState("");
  const [collectionId, setCollectionId] = useState("");
  const [endpoint, setEndpoint] = useState("");
  const [cacheStats, setCacheStats] = useState(null);
  const [healthChecks, setHealthChecks] = useState({});
  const [isCheckingHealth, setIsCheckingHealth] = useState(false);

  const checkApiHealth = async () => {
    setIsCheckingHealth(true);
    const results = {};
    
    for (const api of API_HEALTH_CHECKS) {
      try {
        const startTime = performance.now();
        await ApiGet(`https://clowder.eessi.science/api${api.endpoint}`);
        const endTime = performance.now();
        results[api.name] = {
          status: "Healthy",
          latency: Math.round(endTime - startTime),
          timestamp: new Date().toISOString()
        };
      } catch (error) {
        results[api.name] = {
          status: "Error",
          error: error.message,
          timestamp: new Date().toISOString()
        };
      }
    }
    
    setHealthChecks(results);
    setIsCheckingHealth(false);
  };

  useEffect(() => {
    const loadCacheStats = async () => {
      const { stats } = await lhumosCache.getCachedData();
      setCacheStats(stats);
    };
    loadCacheStats();
  }, []);

  const fetchEndpoint = async () => {
    setIsLoading(true);
    try {
      let url = endpoint.startsWith("http") ? endpoint : 
                endpoint.startsWith("/") ? `https://clowder.eessi.science/api${endpoint}` :
                `https://clowder.eessi.science/api/${endpoint}`;
      const response = await ApiGet(url);
      setApiResponse(response);
    } catch (error) {
      setApiResponse({ error: error.message });
    }
    setIsLoading(false);
  };

  const refreshCache = async () => {
    setIsLoading(true);
    try {
      await lhumosCache.refreshCache();
      const { stats } = await lhumosCache.getCachedData();
      setCacheStats(stats);
      window.location.reload();
    } catch (error) {
      console.error("Cache refresh failed:", error);
    }
    setIsLoading(false);
  };

  if (isLoading) return <LoadingScreen />;

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#4883b8] via-sky-900 to-black p-8">
      <div className="max-w-6xl mx-auto space-y-6">
        {/* Health Monitor */}
        <div className="bg-white/10 backdrop-blur-md rounded-xl p-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-bold text-white flex items-center gap-2">
              <Activity className="h-6 w-6" />
              API Health Monitor
            </h2>
            <button
              onClick={checkApiHealth}
              disabled={isCheckingHealth}
              className="flex items-center gap-2 px-4 py-2 bg-green-500 hover:bg-green-600 text-white rounded-lg transition-colors disabled:opacity-50"
            >
              <Activity className="h-4 w-4" />
              {isCheckingHealth ? "Checking..." : "Check All APIs"}
            </button>
          </div>

          <div className="grid grid-cols-3 gap-4">
            {API_HEALTH_CHECKS.map((api) => (
              <div key={api.name} className="bg-white/5 rounded-lg p-4">
                <h3 className="text-lg font-semibold text-white mb-2">{api.name}</h3>
                {healthChecks[api.name] ? (
                  <div>
                    <div className={`text-sm ${
                      healthChecks[api.name].status === "Healthy" ? "text-green-400" : "text-red-400"
                    }`}>
                      Status: {healthChecks[api.name].status}
                    </div>
                    {healthChecks[api.name].latency && (
                      <div className="text-white/70 text-sm">
                        Latency: {healthChecks[api.name].latency}ms
                      </div>
                    )}
                    <div className="text-white/50 text-xs mt-1">
                      Last checked: {new Date(healthChecks[api.name].timestamp).toLocaleTimeString()}
                    </div>
                  </div>
                ) : (
                  <div className="text-white/50 text-sm">Not checked</div>
                )}
              </div>
            ))}
          </div>
        </div>

        {/* Cache Stats */}
        <CacheStats stats={cacheStats} onRefresh={refreshCache} />

        {/* API Explorer */}
        <div className="bg-white/10 backdrop-blur-md rounded-xl p-6">
          <h2 className="text-2xl font-bold text-white flex items-center gap-2 mb-6">
            <Shell className="h-6 w-6" />
            API Explorer
          </h2>

          <div className="grid grid-cols-2 gap-4 mb-4">
            <div>
              <label className="block text-sm font-medium text-white mb-2">Space ID</label>
              <input
                type="text"
                value={spaceId}
                onChange={(e) => setSpaceId(e.target.value)}
                className="w-full p-2 rounded-lg bg-white/5 border border-white/10 text-white"
                placeholder="Enter Space ID"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-white mb-2">Collection ID</label>
              <input
                type="text"
                value={collectionId}
                onChange={(e) => setCollectionId(e.target.value)}
                className="w-full p-2 rounded-lg bg-white/5 border border-white/10 text-white"
                placeholder="Enter Collection ID"
              />
            </div>
          </div>

          <div className="flex gap-2 mb-4">
            {[
              { name: "Get Space", url: `/spaces/${spaceId}` },
              { name: "Get Collections", url: `/spaces/${spaceId}/collections` },
              { name: "Get Collection", url: `/collections/${collectionId}` },
              { name: "Get Collection Datasets", url: `/collections/${collectionId}/datasets` }
            ].map((qe, idx) => (
              <button
                key={idx}
                onClick={() => setEndpoint(qe.url)}
                className="px-3 py-1 bg-white/5 hover:bg-white/10 text-white rounded-lg text-sm transition-colors"
              >
                {qe.name}
              </button>
            ))}
          </div>

          <div className="flex gap-4 mb-6">
            <input
              type="text"
              value={endpoint}
              onChange={(e) => setEndpoint(e.target.value)}
              className="flex-1 p-2 rounded-lg bg-white/5 border border-white/10 text-white"
              placeholder="Enter API endpoint or full URL"
            />
            <button
              onClick={fetchEndpoint}
              className="flex items-center gap-2 px-6 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-lg transition-colors"
            >
              <Search className="h-4 w-4" />
              Fetch
            </button>
          </div>

          {apiResponse && (
            <div className="bg-white/5 rounded-lg p-4 max-h-[600px] overflow-auto">
              <pre className="text-white text-sm">
                {JSON.stringify(apiResponse, null, 2)}
              </pre>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default DevExplorer;