import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./components/Footer";

import Cecamlogo from "./img/cecamlogo.png";
import Marvellogo from "./img/marvellogo.png";
import Maxlogo from "./img/maxlogo.png";
import DomeLogo from "./img/domelogo.png";
import MultiLogo from "./img/multilogo.png";
import atomPlanet from "./img/atom_planet.png";
import playicon from "./img/playicon.png";
import compass from "./img/compass.png";

function Home() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-[#4883b8] via-sky-900 to-black relative overflow-hidden">
      <ToastContainer />

      <img
        src={atomPlanet}
        alt="Atom Planet"
        className="absolute w-2/3 top-0 -right-40 opacity-20 rotating"
        style={{
          animation: "rotation 120s infinite linear",
          transformOrigin: "center center",
        }}
      />

      <style>
        {`
          @keyframes rotation {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(-360deg); }
          }
          
          .hover-card .card-content {
            opacity: 0;
            transition: all 0.5s ease;
          }
          
          .hover-card:hover .card-content {
            opacity: 1;
            background: rgba(0, 0, 0, 0.8);
          }
          
          .hover-card:hover .card-bg {
            transform: scale(1.1);
          }
          
          .action-button {
            transition: all 0.3s ease;
            position: relative;
            overflow: hidden;
          }
          
          .action-button::after {
            content: '';
            position: absolute;
            top: 0;
            left: -100%;
            width: 100%;
            height: 100%;
            background: linear-gradient(
              90deg,
              transparent,
              rgba(255, 255, 255, 0.2),
              transparent
            );
            transition: 0.5s;
          }
          
          .action-button:hover::after {
            left: 100%;
          }
        `}
      </style>

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 mt-32">
        <div className="mb-24 text-center">
          <h1 className="text-7xl font-bold text-white mb-8 bg-clip-text text-transparent bg-gradient-to-r from-white to-blue-200">
            Learning Hub for Modelling and Simulation
          </h1>
          <p className="text-xl text-white/90 mb-12 max-w-3xl mx-auto leading-relaxed">
            A growing repository of domain specific training material in
            simulation and modeling. Made by researches for researchers, featuring
            interactive lectures, slide navigation, and comprehensive resources.
          </p>
          <div className="flex flex-wrap gap-8 justify-center">
            <Link to="/spaces" className="action-button flex items-center px-8 py-4 rounded-xl bg-gradient-to-r from-[#4883b8] to-sky-600 text-white text-lg font-semibold shadow-lg hover:shadow-xl transition transform hover:scale-105 no-underline">
              <img src={compass} alt="browse spaces" className="w-8 h-8 mr-3" />
              Browse spaces
            </Link>
            <Link to="https://alpha.lhumos.org/player/6/0/65a53f9de4b08f2db4344741/65b2180ae4b08f2db4352ab7" className="action-button flex items-center px-8 py-4 rounded-xl bg-gradient-to-r from-[#4883b8] to-sky-600 text-white text-lg font-semibold shadow-lg hover:shadow-xl transition transform hover:scale-105 no-underline">
              <img src={playicon} alt="Get to Know Lhumos" className="w-8 h-8 mr-3" />
              Get to Know Lhumos!
            </Link>
          </div>
        </div> 

        <div className="backdrop-blur-lg bg-black/20 rounded-2xl p-8 mb-16 shadow-2xl">
          <p className="text-lg text-white/90">
            This website is continuously growing and being expanded. If you encounter any issues or have suggestions, please reach out to us at{" "}
            <a href="mailto:Lhumos@cecam.org" className="text-blue-300 hover:text-blue-200 transition-colors font-semibold">
              Lhumos@cecam.org
            </a>
          </p>
        </div>

        <div className="backdrop-blur-lg bg-black/20 rounded-2xl p-12 shadow-2xl">
          <h2 className="text-4xl font-bold text-white mb-12 text-center">
            Funded by
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-8">
            {[
              { logo: Cecamlogo, title: "CECAM", description: "Fostering computational science for societal progress" },
              { logo: Marvellogo, title: "MARVEL", description: "Design and discovery of novel materials" },
              { logo: Maxlogo, title: "MᴀX", description: "Enabling frontier HPC in the materials domain" },
              { logo: MultiLogo, title: "MultiXscale", description: "EuroHPC JU Centre of Excellence in multiscale modelling" },
              { logo: DomeLogo, title: "DOME", description: "An industrial data marketplace ecosystem based on Open Science and Open Innovation" }
            ].map((space, idx) => (
              <Link
                key={space.title}
                to={`/spaces/${idx}`}
                className="hover-card block rounded-2xl overflow-hidden shadow-xl transition-all duration-300 transform hover:-translate-y-2 no-underline group"
              >
                <div className="relative aspect-square">
                  <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black/20 group-hover:opacity-0 transition-opacity duration-300" />
                  <img
                    src={space.logo}
                    alt={`${space.title} logo`}
                    className="w-full h-full object-cover card-bg transition-transform duration-700"
                  />
                  <div className="card-content absolute inset-0 flex flex-col justify-end p-6 backdrop-blur-sm">
                    <h3 className="text-xl font-bold text-white mb-2">{space.title}</h3>
                    <p className="text-sm text-white/90">{space.description}</p>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;