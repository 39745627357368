import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Video, Search, ArrowRight, Filter } from 'lucide-react';
import { search } from './APImodules';
import { extractCleanText } from './TextContentProcessor';
import LhumosSpaces from '../spaces.json';
import Modal from './Modal';
import lhumosCache from '../components/lhumosCache';
import Card from './Card';

const SearchItem = React.memo(({ result, spaces, spaceColor3, onResultClick }) => {
  const cleanDescription = useMemo(() => 
    extractCleanText(result.description), [result.description]);

  const theme = { accent: spaceColor3 };

  return (
    <Card 
      theme={theme}
      isNested={true}
    >
      <div className="flex gap-4">
        <div className="relative w-36 h-20 flex-shrink-0 rounded-lg overflow-hidden">
          <div
            className="absolute inset-0 bg-cover bg-center"
            style={{
              backgroundImage: `url("https://clowder.eessi.science/fileThumbnail/${result.thumbnail}/blob")`
            }}
          />
          <div className="absolute inset-0 bg-black/40" />
        </div>

        <div className="flex-grow min-w-0">
          <h4 className="text-lg text-white font-semibold mb-1 truncate">
            {result.name}
          </h4>
          {cleanDescription && (
            <p className="text-white/80 text-sm leading-relaxed line-clamp-2 mb-2">
              {cleanDescription}
            </p>
          )}
          <div className="flex flex-wrap gap-2">
            {spaces.map((spaceInfo, idx) => (
              <button
                key={`${result.id}-${idx}`}
                onClick={() => onResultClick(result, spaceInfo)}
                className="flex items-center px-3 py-1.5 rounded-full bg-white/20 text-white text-sm hover:bg-white/30 transition-colors"
                style={{
                  borderLeft: `3px solid ${spaceInfo.spaceDetails?.accent || '#4883b8'}`
                }}
              >
                {spaceInfo.spaceDetails?.iconurl && (
                  <img 
                    src={spaceInfo.spaceDetails.iconurl}
                    alt={spaceInfo.spaceName}
                    className="w-5 h-5 rounded-full mr-2"
                  />
                )}
                View in {spaceInfo.spaceName}
                <ArrowRight size={16} className="ml-2" />
              </button>
            ))}
          </div>
        </div>
      </div>
    </Card>
  );
});

const SpaceFilter = React.memo(({ spaces, selectedSpaces, onToggleSpace }) => (
  <Card theme={{ accent: '#4883b8' }}>
    <div className="flex flex-wrap gap-2">
      {spaces.map(space => (
        <button
          key={space.id}
          onClick={() => onToggleSpace(space.id)}
          className={`flex items-center px-3 py-1.5 rounded-full text-white text-sm transition-colors ${
            selectedSpaces.includes(space.id) ? 'bg-white/30' : 'bg-white/10'
          } hover:bg-white/40`}
          style={{
            borderLeft: `3px solid ${space.accent}`
          }}
        >
          <img 
            src={space.iconurl}
            alt={space.externalid}
            className="w-5 h-5 rounded-full mr-2"
          />
          {space.externalid}
        </button>
      ))}
    </div>
  </Card>
));

function SearchResults({ query, onClose, spaceColor1, spaceColor2, spaceColor3 }) {
  const [searchResults, setSearchResults] = useState({ results: [], count: 0, from: 0, totalSize: 0 });
  const [isLoading, setIsLoading] = useState(true);
  const [lhumosStructure, setLhumosStructure] = useState(null);
  const [selectedSpaces, setSelectedSpaces] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [searchData, cachedData] = await Promise.all([
          search(query, 'dataset'),
          lhumosCache.getCachedData()
        ]);

        const structure = cachedData.rawStructure || (await lhumosCache.refreshCache()).rawStructure;
        setLhumosStructure(structure);
        setSearchResults(searchData || { results: [], count: 0, from: 0, totalSize: 0 });
      } catch (error) {
        console.error("Error fetching data:", error);
        setSearchResults({ results: [], count: 0, from: 0, totalSize: 0 });
      }
      setIsLoading(false);
    };
    fetchData();
  }, [query]);

  const findDatasetInStructure = useMemo(() => (datasetId, structure) => {
    if (!Array.isArray(structure)) return [];
    const spaces = [];
    
    for (const space of structure) {
      const collectionInfo = findDatasetInCollections(datasetId, space.children);
      if (collectionInfo) {
        const spaceDetails = LhumosSpaces.find(s => s.id === space.id);
        if (spaceDetails) {
          spaces.push({ ...collectionInfo, spaceName: space.name, spaceId: space.id, spaceDetails });
        }
      }
    }
    return spaces;
  }, []);

  const findDatasetInCollections = (datasetId, collections) => {
    if (!Array.isArray(collections)) return null;
    for (const collection of collections) {
      if (Array.isArray(collection.children)) {
        const datasetIndex = collection.children.findIndex(child => child.id === datasetId);
        if (datasetIndex !== -1) {
          return { collectionId: collection.id, datasetIndex, collection };
        }
        const nestedResult = findDatasetInCollections(datasetId, collection.children);
        if (nestedResult) return nestedResult;
      }
    }
    return null;
  };

  const handleResultClick = (result, spaceInfo) => {
    const spaceIndex = LhumosSpaces.findIndex(space => space.id === spaceInfo.spaceId);
    if (spaceIndex === -1) return;
    
    onClose();
    navigate(`/player/${spaceIndex}/${spaceInfo.datasetIndex}/${spaceInfo.collectionId}/${result.id}`);
  };

  const toggleSpace = (spaceId) => {
    setSelectedSpaces(prev => 
      prev.includes(spaceId) 
        ? prev.filter(id => id !== spaceId)
        : [...prev, spaceId]
    );
  };

  const filteredResults = useMemo(() => {
    return searchResults.results.filter(result => {
      const spaces = findDatasetInStructure(result.id, lhumosStructure);
      if (selectedSpaces.length === 0) return spaces.length > 0;
      return spaces.some(space => selectedSpaces.includes(space.spaceDetails.id));
    });
  }, [searchResults.results, lhumosStructure, findDatasetInStructure, selectedSpaces]);

  const headerContent = (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-2">
        <Search className="text-white" size={24} />
        <h2 className="text-2xl text-white font-bold">Search Results</h2>
      </div>
      <span className="flex items-center gap-1 bg-white/25 backdrop-blur-sm px-3 py-1.5 rounded text-white">
        <Video size={18} />
        {filteredResults.length} Results
      </span>
    </div>
  );

  const theme = { accent: spaceColor3 };

  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      contentClassName="w-4/5"
      style={{
        backgroundImage: `linear-gradient(to bottom, ${spaceColor2}, rgba(0, 0, 0, 0.8))`
      }}
    >
      <div>
        {headerContent}
        
        <SpaceFilter 
          spaces={LhumosSpaces}
          selectedSpaces={selectedSpaces}
          onToggleSpace={toggleSpace}
        />

        {isLoading ? (
          <div className="text-white text-center py-8">Loading results...</div>
        ) : (
          <div className="space-y-2">
            {filteredResults.map((result) => (
              <SearchItem
                key={result.id}
                result={result}
                spaces={findDatasetInStructure(result.id, lhumosStructure)}
                spaceColor3={spaceColor3}
                onResultClick={handleResultClick}
              />
            ))}
          </div>
        )}
      </div>
    </Modal>
  );
}

export default SearchResults;