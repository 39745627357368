import React, { useState, useEffect, useCallback, memo } from "react";
import { Link } from "react-router-dom";
import { Database, Video, ArrowRight } from "lucide-react";
import Footer from "./components/Footer";
import LhumosSpaces from "./spaces.json";
import LoadingScreen from "./components/LoadingScreen";
import { getSpaces } from "./components/APImodules";
import lhumosCache from "./components/lhumosCache";
import atomPlanet from "./img/atom_planet.png";

const SpaceCard = memo(({ space, stats, idx }) => (
  <div
    className="space-card"
    style={{ '--animation-order': idx }}
  >
    <Link
      to={`/spaces/${idx}`}
      className="block group no-underline"
    >
      <div className="relative h-[22rem] rounded-xl overflow-hidden shadow-lg transition-transform duration-300 hover:-translate-y-0.5">
        <div 
          className="card-bg absolute inset-0 space-card-gradient bg-cover bg-center transition-transform duration-500"
          style={{ backgroundImage: `url(${space.bgurl})` }}
        />

        <div className="relative h-full z-10 p-4 flex flex-col">
          <div className="flex items-center space-x-3">
            <div 
              className="h-14 w-14 rounded-lg overflow-hidden shadow-lg"
              style={{ 
                borderLeft: `4px solid ${space.accent}`,
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                backdropFilter: 'blur(10px)'
              }}
            >
              <img
                src={space.iconurl}
                alt={space.externalid}
                className="h-full w-full object-cover"
                loading="lazy"
              />
            </div>
            <h2 className="text-xl font-bold text-white">
              {space.externalid}
            </h2>
          </div>

          <div className="mt-4 flex-grow">
            <div 
              className="backdrop-blur-sm bg-black/20 rounded-lg p-3 transition-colors duration-300"
              style={{ borderLeft: `4px solid ${space.accent}` }}
            >
              <p className="text-white/90 text-sm line-clamp-5">
                {space.description}
              </p>
            </div>
          </div>

          <div className="mt-auto pt-3">
            <div 
              className="flex justify-between items-center rounded-lg py-2 px-3"
              style={{ 
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                backdropFilter: 'blur(10px)',
                borderLeft: `4px solid ${space.accent}`
              }}
            >
              <div className="flex gap-3">
                <div className="flex items-center gap-1 text-white/80">
                  <Database size={16} />
                  <span className="text-sm">{stats.collections} Collections</span>
                </div>
                <div className="flex items-center gap-1 text-white/80">
                  <Video size={16} />
                  <span className="text-sm">{stats.datasets} Videos</span>
                </div>
              </div>
              <ArrowRight 
                className="text-white/80 card-arrow transition-transform duration-300"
                size={20}
              />
            </div>
          </div>
        </div>
      </div>
    </Link>
  </div>
));

function Spaces() {
  const [isLoading, setIsLoading] = useState(true);
  const [spacesData, setSpacesData] = useState({ spaces: [], stats: {} });

  const countDatasets = useCallback((children) => {
    return children.reduce((count, child) => {
      if (child.group === 'dataset') return count + 1;
      if (child.children) return count + countDatasets(child.children);
      return count;
    }, 0);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await lhumosCache.refreshCache();
        const [apiSpaces, { rawStructure }] = await Promise.all([
          getSpaces(),
          lhumosCache.getCachedData()
        ]);

        const processedData = LhumosSpaces.reduce((acc, lhumosSpace) => {
          const matchingSpace = apiSpaces.find(space => space.id === lhumosSpace.id);
          if (!matchingSpace) return acc;

          const spaceData = rawStructure.find(s => s.id === matchingSpace.id);
          if (spaceData) {
            acc.stats[matchingSpace.id] = {
              collections: spaceData.children?.filter(c => c.group === 'collection').length || 0,
              datasets: countDatasets(spaceData.children || [])
            };
          }

          acc.spaces.push({ ...matchingSpace, ...lhumosSpace });
          return acc;
        }, { spaces: [], stats: {} });

        setSpacesData(processedData);
      } catch (error) {
        console.error("Error fetching spaces data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [countDatasets]);

  if (isLoading) return <LoadingScreen />;

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#4883b8] via-sky-900 to-black relative overflow-hidden">
      <img
        src={atomPlanet}
        alt="Atom Planet"
        className="absolute w-2/3 top-0 -right-40 opacity-20 rotating"
        style={{
          animation: "rotation 120s infinite linear",
          transformOrigin: "center center",
        }}
      />

      <style>
        {`
          @keyframes rotation {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(-360deg); }
          }
          
          @keyframes fadeIn {
            from { opacity: 0; transform: translateY(10px); }
            to { opacity: 1; transform: translateY(0); }
          }
          
          .space-card {
            animation: fadeIn 0.5s ease-out forwards;
            animation-delay: calc(var(--animation-order) * 0.1s);
            opacity: 0;
          }
          
          .space-card-gradient::after {
            content: '';
            position: absolute;
            inset: 0;
            background: linear-gradient(
              to bottom,
              rgba(0, 0, 0, 0.3) 0%,
              rgba(0, 0, 0, 0.6) 100%
            );
            border-radius: 0.75rem;
            transition: opacity 0.3s ease;
          }
          
          .space-card:hover .space-card-gradient::after {
            opacity: 0.9;
          }

          .space-card:hover .card-arrow {
            transform: translateX(2px);
          }
          
          .space-card:hover .card-bg {
            transform: scale(1.05);
          }
        `}
      </style>

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {spacesData.spaces.map((space, idx) => (
            <SpaceCard
              key={space.id}
              space={space}
              stats={spacesData.stats[space.id] || { collections: 0, datasets: 0 }}
              idx={idx}
            />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Spaces;