import React, { useMemo, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Video, Play, Clock, ChevronDown } from "lucide-react";
import { extractCleanText } from './TextContentProcessor';
import Modal from './Modal';
import LhumosSpaces from "../spaces.json";
import Card from './Card';
import SharedFiles from './SharedFiles';
import PdfContainer from './PdfContainer';
import UrlContainer from './UrlContainer';
import ZipContainer from './ZipContainer';
import { getFilesByDatasetId } from './APImodules';

const DatasetItem = React.memo(({ dataset, index, spaceIndex, collectionId, theme }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const cleanDatasetDescription = useMemo(() => 
    extractCleanText(dataset.description), [dataset.description]);
  
  return (
    <Link
      to={`/player/${spaceIndex}/${index}/${collectionId}/${dataset.id}`}
      className="block no-underline group"
    >
      <Card theme={theme} isNested>
        <div className="flex gap-4">
          <div className="relative w-36 h-20 flex-shrink-0 rounded-lg overflow-hidden">
            <div
              className="absolute inset-0 bg-cover bg-center transform transition-transform group-hover:scale-105"
              style={{
                backgroundImage: `url("https://clowder.eessi.science/fileThumbnail/${dataset.thumbnail}/blob")`
              }}
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
            <div 
              className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity"
              style={{ backgroundColor: 'rgba(0, 0, 0, 0.3)' }}
            >
              <div className="w-10 h-10 rounded-full bg-white/20 backdrop-blur-sm flex items-center justify-center">
                <Play size={20} className="text-white ml-1" />
              </div>
            </div>
          </div>

          <div className="flex-grow min-w-0">
            <div className="flex justify-between items-start gap-4 mb-2">
              <h4 className="text-lg text-white font-medium truncate group-hover:text-white/90 transition-colors">
                {dataset.name}
              </h4>
              <div className="flex items-center gap-2">
                <Clock size={14} className="text-white/60" />
                <span className="text-white/60 text-xs">
                  {new Date(dataset.created).toLocaleDateString()}
                </span>
              </div>
            </div>
            {cleanDatasetDescription && (
              <div>
                <p className={`text-white/70 text-sm leading-relaxed ${isExpanded ? '' : 'line-clamp-2'}`}>
                  {cleanDatasetDescription}
                </p>
                {cleanDatasetDescription.length > 150 && (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setIsExpanded(!isExpanded);
                    }}
                    className="text-white/50 text-xs mt-1 hover:text-white/70 transition-colors"
                  >
                    {isExpanded ? 'Show less' : 'Show more'}
                    <ChevronDown 
                      size={12} 
                      className={`inline ml-1 transform transition-transform ${isExpanded ? 'rotate-180' : ''}`}
                    />
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </Card>
    </Link>
  );
});

function CollectionPopup({ collection, datasets, spaceIndex, onClose }) {
  const theme = useMemo(() => LhumosSpaces[spaceIndex], [spaceIndex]);
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
  const [files, setFiles] = useState([]);
  const cleanCollectionDescription = useMemo(() => 
    extractCleanText(collection.description), [collection.description]);

  useEffect(() => {
    const scrollY = window.scrollY;
    document.body.style.position = 'fixed';
    document.body.style.top = `-${scrollY}px`;
    document.body.style.width = '100%';
    document.body.style.overflow = 'hidden';
    
    return () => {
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.width = '';
      document.body.style.overflow = '';
      window.scrollTo(0, scrollY);
    };
  }, []);

  useEffect(() => {
    const fetchFiles = async () => {
      if (!collection.id) return;
      try {
        const filesData = await getFilesByDatasetId(datasets[0].id);
        setFiles(filesData);
      } catch (error) {
        console.error('Error fetching files:', error);
      }
    };
    fetchFiles();
  }, [collection.id, datasets]);

  const headerContent = (
    <>
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center gap-2">
          <h2 className="text-2xl text-white font-bold truncate pr-4">
            {collection.name || collection.collectionname}
          </h2>
        </div>
        <div className="flex items-center gap-4">
          <span className="flex items-center gap-2 bg-white/10 px-4 py-2 rounded-lg backdrop-blur-sm">
            <Video size={18} className="text-white" />
            <span className="text-white font-medium">{datasets.length}</span>
          </span>
        </div>
      </div>

      {cleanCollectionDescription && (
        <div className="bg-black/20 backdrop-blur-sm rounded-lg p-6 w-full mb-6">
          <div>
            <p className={`text-white/90 text-base leading-relaxed ${isDescriptionExpanded ? '' : 'line-clamp-3'}`}>
              {cleanCollectionDescription}
            </p>
            {cleanCollectionDescription.length > 250 && (
              <button
                onClick={() => setIsDescriptionExpanded(!isDescriptionExpanded)}
                className="text-white/50 text-sm mt-3 hover:text-white/70 transition-colors flex items-center"
              >
                {isDescriptionExpanded ? 'Show less' : 'Show more'}
                <ChevronDown 
                  size={16} 
                  className={`ml-1 transform transition-transform ${isDescriptionExpanded ? 'rotate-180' : ''}`}
                />
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );

  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      contentClassName="w-11/12 max-w-8xl"
      style={{
        backgroundImage: `linear-gradient(to bottom, ${theme.color2}, rgba(0, 0, 0, 0.8))`
      }}
    >
      <div className="grid grid-cols-12 gap-8">
        <div className="col-span-8">
          {headerContent}
          <div className="space-y-2">
            {datasets.map((dataset, index) => (
              <DatasetItem 
                key={dataset.id}
                dataset={dataset}
                index={index}
                spaceIndex={spaceIndex}
                collectionId={collection.id}
                theme={theme}
              />
            ))}
          </div>
        </div>

        <div className="col-span-4">
          <SharedFiles collectionId={collection.id} spaceId={spaceIndex} />
          {files.length > 0 && (
            <div
              className="rounded-3xl px-4 pt-2 pb-4 mt-4"
              style={{
                backgroundImage: `linear-gradient(to bottom, ${theme.color2}, rgba(0, 0, 0, 0))`
              }}
            >
              <h5 className="text-white mb-4">Collection files</h5>
              {files.map((file, idx) => {
                if (file.contentType === "application/pdf") {
                  return <PdfContainer key={idx} spaceIndex={spaceIndex}>{file}</PdfContainer>;
                }
                if (file.contentType === "text/url") {
                  return <UrlContainer key={idx}>{file}</UrlContainer>;
                }
                if (file.contentType === "multi/files-zipped") {
                  return <ZipContainer key={idx}>{file}</ZipContainer>;
                }
                return null;
              })}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default CollectionPopup;