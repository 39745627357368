import React, { useEffect, useState, useMemo } from "react";
import Tree from "react-d3-tree";
import LoadingScreen from "./components/LoadingScreen";
import { useParams } from "react-router-dom";
import LhumosSpaces from "./spaces.json";
import {
  RefreshCw,
  Map,
  FolderTree,
  Database,
  ZoomIn,
  ZoomOut,
} from "lucide-react";
import { truncateText } from "./components/TextTruncation";
import lhumosCache from './components/lhumosCache';

const getNodeColor = (nodeDatum) => {
  if (nodeDatum.name === "Lhumos") return "#d8b4fe";
  if (nodeDatum.type === "unauthorized") return "#fdba74";
  if (nodeDatum.children && nodeDatum.children.length > 0) return "#93c5fd";
  if (nodeDatum.type === "dataset") return "#86efac";
  if (nodeDatum.type === "error") return "#ef4444";
  return "#fde047";
};

const Breadcrumbs = ({ node, onNodeClick }) => {
  const getPath = (node) => {
    const path = [];
    let current = node;
    while (current) {
      path.unshift(current);
      current = current.parent;
    }
    const rootNode = { id: "root", name: "Lhumos" };
    const intermediatePath = [];
    let parentNode = path[0].parent;
    while (parentNode) {
      intermediatePath.unshift(parentNode);
      parentNode = parentNode.parent;
    }
    return [rootNode, ...intermediatePath, ...path];
  };

  if (!node) return null;

  const path = getPath(node);

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="inline-flex items-center space-x-1 md:space-x-3">
        {path.map((nodeItem, index) => (
          <li key={nodeItem.id} className="inline-flex items-center">
            {index > 0 && <span className="text-white mx-2">&gt;</span>}
            <button
              onClick={() => onNodeClick(nodeItem)}
              className="inline-flex items-center text-sm font-medium text-white hover:text-blue-200"
            >
              {nodeItem.name}
            </button>
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default function LhumosMap() {
  const [isLoading, setIsLoading] = useState(true);
  const [treeData, setTreeData] = useState(null);
  const [zoom, setZoom] = useState(1);
  const { spaceId } = useParams();
  const [stats, setStats] = useState({
    spaceCount: 0,
    collectionCount: 0,
    datasetCount: 0,
  });
  const [selectedNode, setSelectedNode] = useState(null);
  const [translate, setTranslate] = useState({ x: 0, y: 200 });

  const dimensions = useMemo(() => ({
    width: window.innerWidth - 100,
    height: window.innerHeight - 200,
  }), []);

  const gradientColors = useMemo(() => {
    const spaceDetails = LhumosSpaces.find((s) => s.id === spaceId) || {
      color1: "#4883b8",
      color2: "#4883b8",
    };
    return spaceDetails;
  }, [spaceId]);

  const fetchGraphData = async () => {
    setIsLoading(true);
    try {
      const { treeData: newTreeData, stats: newStats } = await lhumosCache.refreshCache();
      setTreeData(newTreeData);
      setStats(newStats);
    } catch (error) {
      console.error("Error fetching Lhumos structure:", error);
      setTreeData({
        name: "Error Loading Lhumos Structure",
        type: "error",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      try {
        const { treeData, stats } = await lhumosCache.getCachedData();
        setTreeData(treeData);
        setStats(stats);
      } catch (error) {
        console.error("Error loading cached data:", error);
        setTreeData({
          name: "Error Loading Lhumos Structure",
          type: "error",
        });
      }
      setIsLoading(false);
    };

    loadData();
  }, []);

  const getPath = (node) => {
    const path = [];
    let current = node;
    while (current) {
      path.unshift(current);
      current = current.parent;
    }
    const rootNode = { id: "root", name: "Lhumos" };
    const intermediatePath = [];
    let parentNode = path[0]?.parent;
    while (parentNode) {
      intermediatePath.unshift(parentNode);
      parentNode = parentNode.parent;
    }
    return [rootNode, ...intermediatePath, ...path];
  };

  const handleNodeClick = (nodeDatum, evt) => {
    setSelectedNode(nodeDatum);

    const nodeWidth = 400;
    const nodeHeight = 100;
    const newX = window.innerWidth / 2 - (nodeDatum.x + nodeWidth / 2);
    const newY = window.innerHeight / 2 - (nodeDatum.y + nodeHeight / 2);

    if (translate.x !== newX || translate.y !== newY) {
      setTranslate({ x: newX, y: newY });
    }
  };

  const renderCustomNode = ({ nodeDatum, toggleNode }) => (
    <g>
      <circle
        r={20}
        fill={getNodeColor(nodeDatum)}
        stroke={nodeDatum === selectedNode ? "#3b82f6" : "white"}
        strokeWidth={nodeDatum === selectedNode ? "6" : "4"}
        onClick={(evt) => {
          toggleNode();
          handleNodeClick(nodeDatum, evt);
        }}
        className="cursor-pointer hover:stroke-blue-200 transition-colors duration-200"
      />
      <text
        fill="white"
        strokeWidth="1"
        stroke="white"
        fontSize={16}
        x={30}
        dy=".31em"
        className="select-none"
      >
        {truncateText(nodeDatum.name, 40)}
      </text>
      {nodeDatum.description && (
        <text
          fill="white"
          strokeWidth="1"
          stroke="white"
          fontSize={12}
          x={30}
          dy="2em"
          className="select-none"
        >
          {truncateText(nodeDatum.description, 60)}
        </text>
      )}
    </g>
  );

  const handleZoom = (direction) => {
    setZoom((prevZoom) => {
      const newZoom = direction === "in" ? prevZoom * 1.2 : prevZoom / 1.2;
      return Math.min(Math.max(0.1, newZoom), 2);
    });
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div
      className="min-h-screen relative overflow-hidden"
      style={{
        backgroundImage: `linear-gradient(to bottom, ${gradientColors.color1}, ${gradientColors.color2})`,
      }}
    >
      <div className="mx-8 max-w-screen-2xl m-auto">
        <div className="relative z-10 p-2" style={{ height: "80vh" }}>
          <div className="text-white mb-4 flex justify-between items-center">
            <div className="text-xl font-bold flex items-center space-x-4">
              <span>Interactive Data Map</span>
              <div className="flex space-x-2">
                <button
                  onClick={() => handleZoom("out")}
                  className="bg-white text-blue-500 p-1 rounded-full hover:bg-blue-100 transition-colors duration-200"
                  title="Zoom Out"
                >
                  <ZoomOut size={20} />
                </button>
                <button
                  onClick={() => handleZoom("in")}
                  className="bg-white text-blue-500 p-1 rounded-full hover:bg-blue-100 transition-colors duration-200"
                  title="Zoom In"
                >
                  <ZoomIn size={20} />
                </button>
              </div>
            </div>
            <div className="flex space-x-4 items-center">
              <span className="bg-blue-300 text-blue-800 px-2 py-1 rounded flex items-center">
                <Map size={16} className="mr-1" /> {stats.spaceCount} Spaces
              </span>
              <span className="bg-green-300 text-green-800 px-2 py-1 rounded flex items-center">
                <FolderTree size={16} className="mr-1" /> {stats.collectionCount} Collections
              </span>
              <span className="bg-yellow-300 text-yellow-800 px-2 py-1 rounded flex items-center">
                <Database size={16} className="mr-1" /> {stats.datasetCount} Datasets
              </span>
              <button
                onClick={fetchGraphData}
                className="bg-white text-blue-500 p-1 rounded-full hover:bg-blue-100 transition-colors duration-200"
                title="Refresh"
              >
                <RefreshCw size={20} />
              </button>
            </div>
          </div>

          <div className="text-white text-sm mb-2 bg-black bg-opacity-50 p-2 rounded">
            Click nodes to view details and focus. Click and drag to pan, scroll to zoom.
          </div>

          <div className="mb-2">
            {selectedNode && (
              <Breadcrumbs node={selectedNode} onNodeClick={handleNodeClick} />
            )}
          </div>

          <div style={{ width: "100%", height: "calc(100% - 80px)" }}>
            <Tree
              data={treeData}
              orientation="horizontal"
              renderCustomNodeElement={renderCustomNode}
              pathFunc="curve"
              separation={{ siblings: 1, nonSiblings: 2 }}
              translate={translate}
              dimensions={dimensions}
              nodeSize={{ x: 400, y: 100 }}
              zoomable={true}
              collapsible={true}
              initialDepth={1}
              pathClassFunc={() => "stroke-white stroke-1"}
              onNodeClick={handleNodeClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
}