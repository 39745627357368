import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Video, ChevronDown, FolderTree } from 'lucide-react';
import { getDatasetsByCollectionId, getCollectionById, getChildCollectionsByCollectionId } from './APImodules.js';
import { extractCleanText, processTextContent } from './TextContentProcessor';
import LoadingCircle from './LoadingCircle';
import LhumosSpaces from '../spaces.json';
import Card from './Card';

const CompactCollectionContainer = ({ children, spaceIndex, isExpanded: parentIsExpanded, onToggleExpand: parentOnToggleExpand, isTopLevel }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [datasets, setDatasets] = useState([]);
  const [childCollections, setChildCollections] = useState([]);
  const [filteredDescription, setFilteredDescription] = useState('');
  const [isPlaylist, setIsPlaylist] = useState(false);
  const [expandedCollections, setExpandedCollections] = useState(new Set());
  
  const theme = LhumosSpaces[spaceIndex];
  const COLLECTION_ID = children.id;
  const isCurrentlyExpanded = isTopLevel ? parentIsExpanded : expandedCollections.has(COLLECTION_ID);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [datasetsResult, collectionsResult, collectionData] = await Promise.all([
          getDatasetsByCollectionId(COLLECTION_ID, "test"),
          getChildCollectionsByCollectionId(COLLECTION_ID, "test"),
          getCollectionById(COLLECTION_ID)
        ]);

        setDatasets(datasetsResult.filter(dataset => !dataset.name.includes("[files]")));
        setChildCollections(collectionsResult || []);
        setIsPlaylist(collectionsResult?.length > 0);

        if (collectionData?.description) {
          const { cleanText } = processTextContent(collectionData.description);
          setFilteredDescription(cleanText);
        }
      } catch (error) {
        console.error("Error fetching collection data:", error);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [COLLECTION_ID]);

  if (isLoading) return <LoadingCircle />;

  const compactHeader = (
    <div className="flex justify-between items-start">
      <h3 className="text-base text-white font-semibold leading-tight flex-grow truncate">
        {children.name || children.collectionname}
      </h3>
      <div className="flex items-center gap-2 ml-2">
        {isPlaylist ? (
          <span className="flex items-center gap-1 bg-blue-400/30 px-2 py-1 rounded text-white text-sm">
            <FolderTree size={14} />
            {childCollections.length}
          </span>
        ) : (
          <span className="flex items-center gap-1 bg-green-400/30 px-2 py-1 rounded text-white text-sm">
            <Video size={14} />
            {datasets.length}
          </span>
        )}
      </div>
    </div>
  );

  const expandedHeader = (
    <div className="flex justify-between items-center">
      <h3 className="text-lg text-white font-semibold truncate pr-2">
        {children.name || children.collectionname}
      </h3>
      <div className="flex items-center gap-2">
        {isPlaylist ? (
          <span className="flex items-center gap-1 bg-blue-400/30 px-2 py-1 rounded text-white text-sm">
            <FolderTree size={14} />
            {childCollections.length}
          </span>
        ) : (
          <span className="flex items-center gap-1 bg-green-400/30 px-2 py-1 rounded text-white text-sm">
            <Video size={14} />
            {datasets.length}
          </span>
        )}
        <ChevronDown size={20} className="text-white transition-transform rotate-180" />
      </div>
    </div>
  );

  return (
    <div className={`transition-all duration-300 ease-in-out ${isCurrentlyExpanded ? 'col-span-full w-full' : 'col-span-1'}`}>
      <Card
        theme={theme}
        isNested={!isTopLevel}
        header={isCurrentlyExpanded ? expandedHeader : compactHeader}
        onClick={isTopLevel ? parentOnToggleExpand : () => {
          setExpandedCollections(prev => {
            const newSet = new Set(prev);
            prev.has(COLLECTION_ID) ? newSet.delete(COLLECTION_ID) : newSet.add(COLLECTION_ID);
            return newSet;
          });
        }}
      >
        {!isCurrentlyExpanded && filteredDescription && (
          <div className="bg-black/20 rounded p-2">
            <p className="text-white/80 text-sm line-clamp-2">{filteredDescription}</p>
          </div>
        )}

        {isCurrentlyExpanded && (
          <>
            {filteredDescription && (
              <div className="bg-black/20 rounded-lg p-3 mb-4">
                <p className="text-white/80 text-sm">{filteredDescription}</p>
              </div>
            )}
            
            <div onClick={e => e.stopPropagation()}>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3">
                {isPlaylist ? (
                  childCollections.map((collection) => (
                    <CompactCollectionContainer
                      key={collection.id}
                      spaceIndex={spaceIndex}
                      isExpanded={expandedCollections.has(collection.id)}
                      onToggleExpand={() => {
                        setExpandedCollections(prev => {
                          const newSet = new Set(prev);
                          prev.has(collection.id) ? newSet.delete(collection.id) : newSet.add(collection.id);
                          return newSet;
                        });
                      }}
                      isTopLevel={false}
                    >
                      {collection}
                    </CompactCollectionContainer>
                  ))
                ) : (
                  datasets.map((dataset, idx) => (
                    <Link
                      key={dataset.id}
                      to={`/player/${spaceIndex}/${idx}/${COLLECTION_ID}/${dataset.id}`}
                      className="no-underline block bg-black/20 rounded-lg p-3 hover:bg-black/30 transition-colors"
                    >
                      <div className="flex items-start gap-3">
                        <Video className="flex-shrink-0 text-white/60" size={20} />
                        <div className="min-w-0 flex-grow">
                          <h4 className="text-white text-sm font-medium truncate mb-1">
                            {dataset.name}
                          </h4>
                          {dataset.description && (
                            <p className="text-white/60 text-xs line-clamp-2">
                              {extractCleanText(dataset.description)}
                            </p>
                          )}
                        </div>
                      </div>
                    </Link>
                  ))
                )}
              </div>
            </div>
          </>
        )}
      </Card>
    </div>
  );
};

export default CompactCollectionContainer;