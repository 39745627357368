import spacesData from '../spaces.json';

const BASE_URL = 'https://clowder.eessi.science/api';
const API_KEY = '3e3832c8-95e7-4612-9aee-8f48cc30d21e';

async function ApiGet(url, options = {}) {
  const defaultOptions = { headers: { 'X-API-Key': API_KEY } };
  const mergedOptions = { ...defaultOptions, ...options };
  const resp = await fetch(url, mergedOptions);
  const json = await resp.json();
  localStorage.setItem(url, JSON.stringify({ data: json }));
  return json;
}

async function fetchCollectionData(collectionId) {
  const collectionData = await ApiGet(`${BASE_URL}/collections/${collectionId}`);
  const datasets = await ApiGet(`${BASE_URL}/collections/${collectionId}/datasets`);
  const childCollections = await ApiGet(`${BASE_URL}/collections/${collectionId}/getChildCollections`);

  const collectionName = collectionData.collectionname || collectionData.name || 'Unnamed Collection';
  const childCollectionsData = await Promise.all(
    childCollections.map(childCollection => fetchCollectionData(childCollection.id))
  );

  return {
    id: collectionData.id,
    name: collectionName,
    description: collectionData.description,
    thumbnail: collectionData.thumbnail,
    group: 'collection',
    lastModified: collectionData.created,
    creator: collectionData.author,
    datasetCount: datasets.length,
    children: [
      ...datasets.map(dataset => ({
        id: dataset.id,
        name: dataset.name,
        group: 'dataset',
        created: dataset.created,
        description: dataset.description
      })),
      ...childCollectionsData
    ]
  };
}

export async function getLhumosStructure() {
  const allSpaces = await ApiGet(`${BASE_URL}/spaces`);
  const filteredSpaces = spacesData.map(definedSpace => {
    const apiSpace = allSpaces.find(space => space.id === definedSpace.id);
    return apiSpace || { 
      id: definedSpace.id, 
      name: definedSpace.externalid || 'Unnamed Space',
      group: 'space'
    };
  });

  const structuredData = await Promise.all(filteredSpaces.map(async (space) => {
    try {
      const collections = await ApiGet(`${BASE_URL}/spaces/${space.id}/collections`);
      const collectionsWithDatasets = await Promise.all(
        collections.map(async (collection) => {
          try {
            return await fetchCollectionData(collection.id);
          } catch (error) {
            return {
              id: collection.id,
              name: 'Unauthorized Collection',
              group: 'unauthorized-collection',
              children: []
            };
          }
        })
      );

      return {
        id: space.id,
        name: space.name,
        group: 'space',
        children: collectionsWithDatasets
      };
    } catch (error) {
      return {
        id: space.id,
        name: space.name || 'Unauthorized Space',
        group: 'unauthorized-space',
        children: []
      };
    }
  }));

  return structuredData.sort((a, b) => a.name.localeCompare(b.name));
}

export async function search(query, resourceType = null, options = {}) {
  const params = new URLSearchParams({ query });
  if (resourceType) params.append('resource_type', resourceType);
  Object.entries(options).forEach(([key, value]) => {
    if (value) params.append(key, value);
  });
  return await ApiGet(`${BASE_URL}/search?${params.toString()}`);
}

export async function getFilesByDatasetId(datasetId) {
  return await ApiGet(`${BASE_URL}/datasets/${datasetId}/files`);
}

export async function getFileMetadataById(fileId) {
  return await ApiGet(`${BASE_URL}/files/${fileId}/metadata.jsonld`);
}

export async function getDatasetsByCollectionId(collectionId, key) {
  return await ApiGet(`${BASE_URL}/collections/${collectionId}/datasets/?key=${key}`);
}

export async function getDatasetsBySpaceId(spaceId) {
  return await ApiGet(`${BASE_URL}/spaces/${spaceId}/datasets`);
}

export async function getDatasetMetadataByFileId(fileId) {
  return await ApiGet(`${BASE_URL}/files/${fileId}/metadata.jsonld`);
}

export async function getCollectionById(collectionId) {
  return await ApiGet(`${BASE_URL}/collections/${collectionId}`);
}

export async function getCollectionsBySpaceId(spaceId) {
  return await ApiGet(`${BASE_URL}/spaces/${spaceId}/collections`);
}

export async function getChildCollectionsByCollectionId(collectionId, key) {
  return await ApiGet(`${BASE_URL}/collections/${collectionId}/getChildCollections/?key=${key}`);
}

export async function getSpaceById(spaceId) {
  return await ApiGet(`${BASE_URL}/spaces/${spaceId}`);
}

export async function getSpaces(limit = 64) {
  return await ApiGet(`${BASE_URL}/spaces?limit=${limit}`);
}

export default ApiGet;