import React from 'react';

const Card = ({
  children,
  header,
  theme,
  isNested = false,
  className = '',
  onClick
}) => {
  return (
    <div className={`w-full transition-all duration-300 mb-4 ${className}`}>
      <div 
        className="rounded-lg overflow-hidden hover:shadow-lg relative"
        style={{
          backgroundColor: isNested ? 
            'rgba(255, 255, 255, 0.05)' : 
            'rgba(0, 0, 0, 0.15)',
          border: '1px solid rgba(255, 255, 255, 0.1)',
          boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(255, 255, 255, 0.05)'
        }}
        onClick={onClick}
      >
        <div 
          className="absolute left-0 top-0 bottom-0 w-1"
          style={{
            backgroundColor: theme.accent,
            boxShadow: `0 0 8px ${theme.accent}40`
          }}
        />
        {header && (
          <div className="p-6 cursor-pointer">
            {header}
          </div>
        )}
        <div className={`px-6 pb-6 ${header ? 'pt-0' : 'pt-6'}`}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Card;