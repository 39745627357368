import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { X } from 'lucide-react';

const Backdrop = ({ onClick }) => (
  <div 
    className="fixed z-[9998] bg-black/50 backdrop-blur-sm"
    onClick={onClick}
    style={{ 
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    }}
  />
);

const Modal = ({
  isOpen,
  onClose,
  children,
  className = "",
  containerClassName = "",
  contentClassName = "",
  style = {},
  contentStyle = {}
}) => {
  useEffect(() => {
    if (isOpen) {
      const scrollY = window.scrollY;
      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollY}px`;
      document.body.style.width = '100%';
      document.body.style.overflow = 'hidden';
      
      return () => {
        document.body.style.position = '';
        document.body.style.top = '';
        document.body.style.width = '';
        document.body.style.overflow = '';
        window.scrollTo(0, scrollY);
      };
    }
  }, [isOpen]);

  if (!isOpen) return null;

  const modalContent = (
    <>
      <Backdrop onClick={onClose} />
      <div 
        className={`fixed z-[9999] overflow-y-auto ${containerClassName}`}
        onClick={onClose}
        style={{
          top: 0,
          left: 0,
          right: 0,
          bottom: 0
        }}
      >
        <div className="min-h-screen w-full px-4 py-8 flex items-center justify-center">
          <div 
            className={`relative w-full mx-auto ${contentClassName}`} 
            style={contentStyle}
          >
            <button
              onClick={onClose}
              className="absolute -top-4 -right-4 text-white/90 p-2 rounded-full hover:bg-black/40 bg-black/20 backdrop-blur-sm z-10"
            >
              <X size={24} />
            </button>
            <div
              className={`rounded-xl shadow-lg p-6 relative ${className}`}
              onClick={e => e.stopPropagation()}
              style={style}
            >
              {children}
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return createPortal(modalContent, document.body);
};

export default Modal;