import Navbar from "./components/Navbar";
import Home from "./Home";
import Player from "./Player";
import PlayerEditor from "./PlayerEditor.js";
import PlaylistEditor from "./PlaylistEditor.js"; 
import Spaces from "./Spaces";
import LhumosMap from "./Map";
import SingleSpace from "./SingleSpace.js";
import DevExplorer from "./DevExplorer";
import User from "./User";
import { Routes, Route, useParams } from "react-router-dom";
import { useEffect } from "react";
import PlausibleAnalytics from './components/Plausible';

function App() {
 return (
   <div>
     <Navbar />
     <PlausibleAnalytics />
     <Routes>
       <Route exact path="/" element={<Home />} />
       <Route path="/spaces" element={<Spaces />} />
       <Route path="/map" element={<LhumosMap />} />
       <Route path="/spaces/:spaceId" element={<SingleSpace />} />
       <Route path="/player/:spaceId/:videoNumber/:collectionId/:datasetId" element={<Player />}/>
       <Route path="/editor/:spaceId/:videoNumber/:collectionId/:datasetId" element={<PlayerEditor />}/>
       <Route path="/editor/:spaceId/:videoNumber/:collectionId" element={<PlaylistEditor />}/>
       <Route path="/user" element={<User />} />
       <Route path="/devexplorer" element={<DevExplorer />} />
     </Routes>
   </div>
 );
}

export default App;