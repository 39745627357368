import React, { useState, useEffect } from "react";
import { ChevronDown, FolderTree, Video } from "lucide-react";
import { getDatasetsByCollectionId, getCollectionById, getChildCollectionsByCollectionId } from "./APImodules.js";
import { processTextContent } from './TextContentProcessor';
import LhumosSpaces from "../spaces.json";
import LoadingCircle from "./LoadingCircle";
import CollectionPopup from "./CollectionPopup";
import Card from "./Card";

function CollectionContainer({ children, spaceIndex, isExpanded: parentIsExpanded, onToggleExpand: parentOnToggleExpand, isTopLevel, activeCollectionId }) {
  const [isLoading, setIsLoading] = useState(true);
  const [datasets, setDatasets] = useState([]);
  const [isPlaylist, setIsPlaylist] = useState(false);
  const [collections, setCollections] = useState([]);
  const [filteredDescription, setFilteredDescription] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [expandedCollections, setExpandedCollections] = useState(new Set());
  
  const theme = LhumosSpaces[spaceIndex];
  const COLLECTION_ID = children?.id;
  const isCurrentlyExpanded = isTopLevel ? parentIsExpanded : expandedCollections.has(COLLECTION_ID);

  useEffect(() => {
    if (COLLECTION_ID) {
      setIsLoading(true);
      fetchCollectionData();
    }
  }, [COLLECTION_ID]);

  const fetchCollectionData = async () => {
    try {
      const [datasetsResult, collectionsResult] = await Promise.all([
        fetchDatasets(),
        fetchCollections()
      ]);
      setDatasets(datasetsResult);
      setCollections(collectionsResult);
      setIsPlaylist(collectionsResult.length > 0);
    } catch (error) {
      console.error("Error fetching collection data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchDatasets = async () => {
    try {
      const result = await getDatasetsByCollectionId(COLLECTION_ID, "test");
      if (!result) return [];
      
      const filteredResult = result.filter(dataset => !dataset.name.includes("[files]"));
      const collectionData = await getCollectionById(COLLECTION_ID);
      
      if (collectionData) {
        const { cleanText, jsonContent } = processTextContent(collectionData.description);
        setFilteredDescription(cleanText);
        return jsonContent.order || filteredResult;
      }
      return filteredResult;
    } catch (error) {
      console.error("Error fetching datasets:", error);
      return [];
    }
  };

  const fetchCollections = async () => {
    return await getChildCollectionsByCollectionId(COLLECTION_ID, "test") || [];
  };

  const handleClick = (e) => {
    e.stopPropagation();
    if (isPlaylist) {
      if (isTopLevel) {
        parentOnToggleExpand();
      } else {
        setExpandedCollections(prev => {
          const newSet = new Set(prev);
          newSet.has(COLLECTION_ID) ? newSet.delete(COLLECTION_ID) : newSet.add(COLLECTION_ID);
          return newSet;
        });
      }
    } else {
      setIsPopupOpen(true);
    }
  };

  if (isLoading) return <LoadingCircle />;
  if (!children) return null;

  const header = (
    <div className="flex justify-between items-center">
      <h3 className="text-base text-white font-semibold leading-tight truncate pr-2">
        {children.collectionname || children.name}
      </h3>
      {isPlaylist ? (
        <div className="flex items-center gap-2">
          <span className="flex items-center gap-1 bg-blue-400/30 px-2 py-1 rounded text-white text-sm">
            <FolderTree size={14} />
            {collections.length}
          </span>
          <ChevronDown 
            size={20}
            className={`text-white transition-transform duration-300 ${isCurrentlyExpanded ? "rotate-180" : ""}`}
          />
        </div>
      ) : (
        <span className="flex items-center gap-1 bg-green-400/30 px-2 py-1 rounded text-white text-sm">
          <Video size={14} />
          {datasets.length}
        </span>
      )}
    </div>
  );

  return (
    <>
      <Card 
        theme={theme}
        isNested={!isTopLevel}
        header={header}
        onClick={handleClick}
        className="transition-all duration-300"
        padding="3"
        contentPadding="3"
      >
        {filteredDescription && (
          <div className="mt-2 backdrop-blur-sm rounded-lg p-2 bg-black/20">
            <p className="text-white/80 text-sm line-clamp-2">{filteredDescription}</p>
          </div>
        )}
        {isPlaylist && isCurrentlyExpanded && (
          <div className="space-y-3 mt-3">
            {collections.map((collection, idx) => (
              <CollectionContainer 
                key={collection.id} 
                spaceIndex={spaceIndex} 
                index={idx} 
                isExpanded={expandedCollections.has(collection.id)}
                onToggleExpand={() => {}}
                isTopLevel={false}
                activeCollectionId={activeCollectionId}
              >
                {collection}
              </CollectionContainer>
            ))}
          </div>
        )}
      </Card>

      {isPopupOpen && (
        <CollectionPopup
          collection={children}
          datasets={datasets}
          spaceIndex={spaceIndex}
          onClose={() => setIsPopupOpen(false)}
        />
      )}
    </>
  );
}

export default CollectionContainer;