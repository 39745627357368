import { getLhumosStructure } from './APImodules';
import LhumosSpaces from '../spaces.json';

class LhumosCache {
  constructor() {
    this.memoryCache = null;
    this.fetchPromise = null;
  }

  processChildren(children, stats) {
    if (!children) return [];
    
    return children.map((child) => {
      if (child.group === 'space') {
        const spaceDetails = LhumosSpaces.find(space => space.id === child.id);
        const processedChildren = this.processChildren(child.children, stats);
        const spaceCounts = this.calculateSpaceCounts(processedChildren);
        
        stats.collectionCount += spaceCounts.collections;
        stats.datasetCount += spaceCounts.datasets;

        return {
          name: spaceDetails?.name || child.name,
          children: processedChildren,
          type: 'space',
          id: child.id,
          stats: spaceCounts
        };
      } 
      
      if (child.group === 'collection' || child.group === 'unauthorized-collection') {
        const processedChildren = this.processChildren(child.children, stats);
        const collectionStats = this.calculateCollectionStats(child, processedChildren);
        
        stats.collectionCount++;
        stats.datasetCount += collectionStats.datasets;

        return {
          name: child.name || 'Unnamed Collection',
          description: child.description,
          children: processedChildren,
          type: child.group === 'unauthorized-collection' ? 'unauthorized' : 'collection',
          id: child.id,
          lastModified: child.lastModified,
          creator: child.creator,
          stats: collectionStats
        };
      } 
      
      if (child.group === 'dataset') {
        stats.datasetCount++;
        return {
          name: child.name,
          type: 'dataset',
          id: child.id,
          created: child.created,
          description: child.description
        };
      }

      return { name: 'Unauthorized Space', type: 'unauthorized' };
    });
  }

  calculateSpaceCounts(children) {
    return children.reduce((counts, child) => {
      if (child.type === 'collection') counts.collections++;
      if (child.type === 'dataset') counts.datasets++;
      if (child.stats) {
        counts.collections += child.stats.collections;
        counts.datasets += child.stats.datasets;
      }
      return counts;
    }, { collections: 0, datasets: 0 });
  }

  calculateCollectionStats(collection, children) {
    const baseDatasets = collection.datasetCount || 0;
    return children.reduce((stats, child) => {
      if (child.type === 'dataset') stats.datasets++;
      if (child.type === 'collection') {
        stats.collections++;
        if (child.stats) {
          stats.datasets += child.stats.datasets;
          stats.collections += child.stats.collections;
        }
      }
      return stats;
    }, { collections: 0, datasets: baseDatasets });
  }

  async fetchAndProcessData() {
    try {
      const stats = { spaceCount: 0, collectionCount: 0, datasetCount: 0 };
      const rawStructure = await getLhumosStructure();
      stats.spaceCount = rawStructure.length;

      const root = {
        name: 'Lhumos',
        children: rawStructure.map((space) => ({
          name: space.name,
          children: this.processChildren(space.children, stats),
          id: space.id,
          group: space.group
        }))
      };

      this.memoryCache = {
        treeData: root,
        rawStructure,
        stats,
        timestamp: Date.now()
      };

      // Store minimal data in localStorage
      localStorage.setItem('lhumosStats', JSON.stringify(stats));
      localStorage.setItem('lhumosTimestamp', Date.now().toString());

      return this.memoryCache;
    } catch (error) {
      console.error('Error fetching Lhumos structure:', error);
      throw error;
    }
  }

  isCacheValid() {
    if (this.memoryCache) {
      const timestamp = this.memoryCache.timestamp;
      return Date.now() - timestamp < 24 * 60 * 60 * 1000;
    }
    const timestamp = localStorage.getItem('lhumosTimestamp');
    return timestamp && (Date.now() - parseInt(timestamp) < 24 * 60 * 60 * 1000);
  }

  async getCachedData() {
    if (this.memoryCache && this.isCacheValid()) {
      return this.memoryCache;
    }

    if (this.fetchPromise) return this.fetchPromise;

    this.fetchPromise = this.fetchAndProcessData();
    const result = await this.fetchPromise;
    this.fetchPromise = null;
    return result;
  }

  async getSpaceStats(spaceId) {
    const { treeData } = await this.getCachedData();
    const space = treeData.children.find(space => space.id === spaceId);
    if (!space) return null;

    return {
      totalCollections: space.stats?.collections || 0,
      totalDatasets: space.stats?.datasets || 0,
      topCollections: this.getTopCollectionsByDatasets(space, 5)
    };
  }

  getTopCollectionsByDatasets(space, limit = 5) {
    const collections = [];
    this.flattenCollections(space, collections);
    
    return collections
      .sort((a, b) => (b.stats?.datasets || 0) - (a.stats?.datasets || 0))
      .slice(0, limit)
      .map(collection => ({
        id: collection.id,
        name: collection.name,
        datasetsCount: collection.stats?.datasets || 0
      }));
  }

  flattenCollections(node, result) {
    if (node.type === 'collection') result.push(node);
    if (node.children) node.children.forEach(child => this.flattenCollections(child, result));
  }

  findNodeById(root, id) {
    if (root.id === id) return root;
    if (!root.children) return null;
    
    for (const child of root.children) {
      const found = this.findNodeById(child, id);
      if (found) return found;
    }
    return null;
  }

  async refreshCache() {
    this.memoryCache = null;
    localStorage.removeItem('lhumosStats');
    localStorage.removeItem('lhumosTimestamp');
    return this.fetchAndProcessData();
  }

  async searchInCache(query) {
    const { treeData } = await this.getCachedData();
    const results = [];
    
    const searchInNode = (node, path = []) => {
      const currentPath = [...path, node];
      if (node.name.toLowerCase().includes(query.toLowerCase())) {
        results.push({ node, path: currentPath });
      }
      if (node.children) {
        node.children.forEach(child => searchInNode(child, currentPath));
      }
    };

    searchInNode(treeData);
    return results;
  }

  async getStats() {
    return (await this.getCachedData()).stats;
  }
}

const lhumosCache = new LhumosCache();

setInterval(async () => {
  if (!lhumosCache.isCacheValid()) {
    try {
      await lhumosCache.refreshCache();
    } catch (error) {
      console.error('Auto-refresh failed:', error);
    }
  }
}, 60 * 60 * 1000);

export default lhumosCache;